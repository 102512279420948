<template>
    <v-app>
        <v-container fluid class="main-container">
            <v-stepper v-model="stepper" vertical>
                <div style="display: flex; justify-content: center; margin-top: 20px;">
                    <v-img
                        max-width="100"
                        src="@/assets/images/zapermenu-min.png"
                    />
                </div>

                <div style="display: flex; justify-content: center; margin: 20px;">
                    <h2 style="text-align: center;">
                        Bem-vindo(a)! Vamos Cadastrar Sua Empresa
                    </h2>
                </div>

                <v-stepper-step :complete="stepper > 1" step="1">
                    Insira seu número de WhatsApp
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card class="mb-12" elevation="0">
                        <v-card-title>
                            Enviaremos um código de verificação no seu WhatsApp
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                ref="whatsapp"
                                label="Whatsapp"
                                v-model="whatsapp"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                type="tel"
                                hide-details
                                outlined
                                dense
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :disabled="whatsapp.length < 14"
                                @click="sendCode"
                                color="primary"
                                block
                                :loading="loading.sendCode"
                            >Enviar código</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 2" step="2">
                    Insira o código de verificação
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card class="mb-12" elevation="0">
                        <v-card-title>
                            Digite o código que enviamos no seu whatsapp
                        </v-card-title>
                        <v-card-text>
                            <v-otp-input
                                ref="verificationCode"
                                length="6"
                                type="tel"
                                v-model="verificationCode"
                                class="mb-3 mx-6"
                                style="width: 220px;"
                                dense
                            />
                            <v-btn
                                @click="sendCode"
                                :disabled="countDown > 0"
                                text
                                small
                                color="primary"
                            >
                                {{ countDown > 0 ? `(${countDown})` : '' }} Reenviar código
                            </v-btn>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :disabled="verificationCode.length !== 6"
                                @click="confirmCode"
                                color="primary"
                                block
                                :loading="loading.confirmCode"
                            >Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 3" step="3">
                    Configure seu usuário
                </v-stepper-step>

                <v-stepper-content step="3">
                    <v-card elevation="0">
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field
                                    ref="nome"
                                    label="Nome Completo"
                                    type="text"
                                    v-model="nome"
                                    :rules="[rules.fullName]"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    label="CPF"
                                    type="text"
                                    v-model="cpf"
                                    v-mask="['###.###.###-##']"
                                    :rules="[rules.cpf]"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    label="Email"
                                    prepend-icon="mail"
                                    v-model="email"
                                    :rules="[rules.email]"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    label="Senha"
                                    v-model="password"
                                    prepend-icon="lock"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="toggleShowPassword('showPassword')"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.empty]"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    label="Repetir Senha"
                                    v-model="repetirSenha"
                                    prepend-icon="lock"
                                    :type="showPasswordConfirm ? 'text' : 'password'"
                                    @click:append="toggleShowPassword('showPasswordConfirm')"
                                    :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[value => rules.passwordMatch(password, value)]"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    label="Nome da Empresa"
                                    v-model="nomeFantasia"
                                    :rules="[rules.empty]"
                                    outlined
                                    dense
                                />
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="mt-6">
                            <v-btn
                                @click="cadastrar"
                                :loading="loading.cadastrar"
                                color="primary"
                                block
                            >Criar Conta</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper>
        </v-container>

        <v-snackbar
            v-model="snackbar.show"
            :timeout="2500"
            elevation="24"
            :color="snackbar.type === 'success' ? 'green accent-4' : 'error'"
        >
            <v-icon color="white">
                mdi-{{ snackbar.type === 'success' ? 'check-circle-outline' : 'close-circle-outline' }}
            </v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </v-app>
</template>

<script>
import {
    isValidCpf,
    isValidEmail,
    isValidPersonName
} from '../../utils/validate';

export default {
    name: 'RegistroNew',

    data: () => ({
        cpf: '',
        nome: '',
        email: '',
        stepper: 1,
        password: '',
        whatsapp: '',
        nomeFantasia: '',
        repetirSenha: '',
        showPassword: true,
        showPasswordConfirm: true,
        loading: {
            cadastrar: false,
            sendCode: false,
            confirmCode: false,
        },
        verificationCode: '',
        countDown: 60,
        snackbar: { show: false, text: '', type: '' },
        rules: {
            email: value => isValidEmail(value) || 'E-mail inválido',
            empty: value => !!value.trim() || 'Preenchimento obrigatório',
            fullName: value => isValidPersonName(value) || 'Informe o nome completo',
            cpf: value => isValidCpf(value) || 'CPF inválido',
            passwordMatch: (password, repetirSenha) => password === repetirSenha || 'Senha não confere',
        },
    }),

    mounted() {
        this.$nextTick(() => this.$refs.whatsapp.focus());
    },

    methods: {
        sendCode() {
            this.loading.sendCode = true;
            this.$http.post('empresa/verification/send', { whatsapp: this.whatsapp }).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.startCountDown();
                this.stepper = 2;
                this.$nextTick(() => this.$refs.verificationCode.focus());
                this.notify('Um código de verificação foi enviado em seu whatsapp');
            })
            .catch(e => alert('Não foi possivel concluir a operação. Erro: ' + e))
            .finally(() => (this.loading.sendCode = false));
        },

        confirmCode() {
            this.loading.confirmCode = true;
            this.$http.post('empresa/verification/confirm', { whatsapp: this.whatsapp, code: this.verificationCode }).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.stepper = 3;
                this.$nextTick(() => this.$refs.nome.focus());
                this.notify('Whatsapp confirmado');
            })
            .catch(e => alert('Não foi possivel concluir a operação. Erro: ' + e))
            .finally(() => (this.loading.confirmCode = false));
        },

        cadastrar() {
            if (!this.$refs.form.validate()) {
                this.notify('Formulário contém erros');
                return;
            }

            const data = {
                cnpj: '00.000.000/0000-00',
                cpf: this.cpf,
                name: this.nome.trim(),
                nome: this.nome.trim(),
                email: this.email,
                whatsapp: this.whatsapp,
                fantasia: this.nomeFantasia.trim(),
                razao_social: this.nomeFantasia.trim(),
                password: this.password,
                uf: '-',
                cep: '-',
                rua: '-',
                bairro: '-',
                numero: '-',
                municipio: '-',
            };

            this.loading.cadastrar = true;
            this.$http.post('empresa/auto-cadastro', data).then(resp => {
                console.log(resp);
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Cadastro realizado com sucesso');

                this.$router.push({
                    path: '/login',
                    query: {
                        email: this.email,
                        senha: this.password,
                    },
                });
            })
            .catch(e => alert('Não foi possivel efetuar o cadastro ' + e))
            .finally(() => (this.loading.cadastrar = false));
        },

        toggleShowPassword(field) {
            this[field] = !this[field];
            this.$forceUpdate();
        },

        notify(text, type = 'success') {
            this.snackbar = { show: true, text, type };
        },

        startCountDown() {
            this.countDown = 60;
            const interval = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    clearInterval(interval);
                }
            }, 1000);
        },
    },
}
</script>

<style scoped>
.main-container {
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
}

.row {
    height: 55px;
}

.whatsapp-button {
    position: fixed;
    z-index: 100;
    right: 15px;
    bottom: 15px;
    height: 80px;
    cursor: pointer;
}

.v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
}
</style>
